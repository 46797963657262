<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
      
        <v-row class="m-2" :style="`direction:`+lang.dir+`;margin:5px;margin-top:10px;margin-left:30px;margin-right:30px;`">
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.ldir" class="text-end">
                <button id="multiply-button" :style="`direction:`+lang.dir" :class="`multiply-button-`+lang.lalgin" @click="addnew({id:0})" v-b-toggle.add_category>
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add}}</div>
                </button>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="product_categories"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:35%;" class="text-center">{{ row.item.namear }}</td>
                            <td style="width:35%;" class="text-center" nowrap>{{ row.item.nameen }}</td>
                            <td style="width:10%;" class="text-center" nowrap>{{ row.item._type }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item._parent }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.subcats }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.products }}</td>
                            <td style="width:10%;" class="text-center">
                                <b-button class="btn btn-sm btn-prrmary m-1" style="background:#0d6efd !important" @click="addnew(row.item)" v-b-toggle.add_category>{{lang.update}}</b-button>
                                <b-button class="btn btn-sm btn-danger m-1" v-if="row.item.products == 0 && row.item.subcats == 0" @click="delItem(row.item)">{{lang.delete}}</b-button>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
        <products-addcats ref="AddCategory" />
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import ProductsAddcats from '@/components/products-addcats.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
  components: { breadCrumbs, TabsComp, ProductsAddcats, HeaderPortrate,Footer},
    name: 'Products',
    data() {
        return {
            active_tab:0,
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            product_categories: [],
        }
    },
    created() {
        this.getCats();
    },
    methods: {
        delItem(item) {
            const post = new FormData();
            post.append("type","DelCats");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                this.getCats();
            });
        },
        addnew(item){
            if(item.id == 0){
                this.$refs.AddCategory.namear = '';
                this.$refs.AddCategory.nameen = '';
                this.$refs.AddCategory.parentid = 0;
                this.$refs.AddCategory.changeType(1);
                this.$refs.AddCategory.id = 0;
            }else{
                this.$refs.AddCategory.namear = item.namear;
                this.$refs.AddCategory.nameen = item.nameen;
                this.$refs.AddCategory.parentid = item.parentid == 0 ? 0 : item.parentid;
                this.$refs.AddCategory.changeType(item.type == 0 ? 0 : 1);
                this.$refs.AddCategory.id = item.id;
            }
        },
        getCats(){
            const post = new FormData();
            post.append("type","getPCats");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all");
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                // console.log(p);
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push(p.results.data[i]);
                        }
                    }
                }
                this.product_categories = t;
            });
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                {
                    text: this.lang.category_name_ar,
                    align: 'center',
                    filterable: false,
                    value: 'namear',
                },
                {
                    text: this.lang.category_name_en,
                    align: 'center',
                    filterable: false,
                    value: 'nameen',
                },
                {
                    text: this.lang.category_type,
                    align: 'center',
                    filterable: false,
                    value: 'type',
                },
                {
                    text: this.lang.category_parent,
                    align: 'center',
                    filterable: false,
                    value: 'parentid',
                },
                {
                    text: this.lang.subcat_count,
                    align: 'center',
                    filterable: false,
                    value: 'subcat_count',
                },
                {
                    text: this.lang.products,
                    align: 'center',
                    filterable: false,
                    value: 'products',
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    value: '',
                },
            ]
        },
        tabs: function() {
            let m =  [];
            if(this.$store.state.licenseType.fullversion){
                m.push({
                    index:0,name:this.lang.vendors,href:'/inventory/vendors',class:'mytab3',
                })
                m.push({
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab5',
                })
            }
                m.push({
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab'
                })
                m.push({
                    index:2,name:this.lang.products,href:'/inventory/products', class: 'mytab1'
                })
            if(this.$store.state.licenseType.fullversion){
                m.push({
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab2'
                })
                m.push({
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab3'
                })
                m.push({
                    index:5,name:'الاصناف المنتهية',href:'/inventory/finished', class: 'mytab4'
                })
                m.push({
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4'
                })
            }

            return m;
        },
        items: function() {
            return {
                    text: this.lang.product_categories,
                    disabled: true,
                    to: '/inventory/products',
                }
        }
    },
}
</script>